$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.tred-section {
  padding: 64px 15px;
  background-color: #F3F4FA;
  h2.tred-heading {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin: 0 0 32px 0;
    padding-left: 140px;
    @media (max-width: $screen-md-max) {
      padding-right: 5px;
      padding-left: 0px;
      }
  }
  h3.tred-subheading {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 8px 0;
    padding-left: 140px;
    @media (max-width: $screen-md-max) {
      padding-right: 5px;
      padding-left: 0px;
    }
  }
  .tred-text{
    font-size: 16px;
    line-height: 24px;
    padding-left: 140px;
    @media (max-width: $screen-md-max) {
      padding-right: 5px;
      padding-left: 0px;
    }
  }
  .tred-at-img {
    width: 90%;
    margin: auto;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 82%;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 115%;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
      width: 239px;
    }
  }
}