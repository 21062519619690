$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.ensure-trust {
  padding: 64px 15px;
  background-color: #F3F4FA;
  .trust-img {
    width: 90%;
    margin: auto;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 82%;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 115%;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
      width: 239px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin: 0;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 32px;
    }
  }
  .trust-section {
    margin-left: 80px;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      margin-left: 24px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-left: 15px;
    }
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }
  .trust-item {
    margin-top: 32px;
    display: flex;
    .trust-item-icon {
      .trust-icon {
        width: auto;
        height: auto;
      }
    }
    .trust-item-content {
      margin-left: 16px;
      h3 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
        margin: 0 0 4px 0;
      }
      p {
        margin: 0;
      }
      a {
        color: #2d363e;
        text-decoration: underline;
      }
    }
  }
  .faq-link {
    margin-top: 32px;
    a {
      color: #2d363e;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
