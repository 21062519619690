$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.my-area-section {
  background: #FFFFFF;
  padding-top: 48px;
  max-width: 1220px;
  margin: auto;
  ul {
    margin: 0;
  }
  .linkDiv {
    line-height: 2;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      a {
        line-height: 1.5;
      }
    }
    @media (max-width: $screen-sm-max) {
      a {
        line-height: 1.5;
      }
    }
  };
  .accordion-panel.twoListColumns .accordion-panel-content {
    display: flex;
    flex-wrap: wrap;
    .list.columns {
      flex: 0 1 20%;
      @media (max-width: $screen-sm-max) {
        flex: 0 1 100%;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .textStyle {
    padding-left: 10px;
    padding-bottom: 0.01%;
  };
  .listStyle {
    padding-left: 4%;
  };
}
