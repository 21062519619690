$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.buyer-landing-hero {
  position: relative;
  width: 100%;
  height: 643px;

  @media (max-width: $screen-xs-max) {
    height: 416px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  .hero-box {
    text-align: left;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      margin: 0 auto;
    }
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    z-index: 1029;
    width: 1220px;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 995px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 736px;
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
      text-align: center;
    }
    h1 {
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-top: 10px;
      margin-bottom: 16px;
      @media (max-width: $screen-xs-max) {
        margin-top: 0;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
      }
    }
    p {
      a {
        color: white;
        text-decoration: underline;
        font-weight: 700;
      }
    }
    .psx-logo {
      @media (max-width: $screen-xs-max) {
        margin-bottom: 24px;
      }
    }
    .psx-logo-container {
      @media (max-width: $screen-xs-max) {
        width: 160px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .hero-button-box{
      display: flex;
      margin-bottom: 16px;
      @media (max-width: $screen-xs-max) {
        padding-right: 15px;
        padding-left: 15px;
      }
      .hero-button {
        display: flex;
        justify-content: center;
        min-width: 156px;
        margin-right: 16px;
        @media (max-width: $screen-xs-max) {
          min-width: 48%;
          margin-right: 10px;
          &.last {
            margin-right: 0;
          }
        }
      }
    }
  }
}
