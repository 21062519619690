.shop-by-style {
  background: #FFFFFF;
  padding: 64px 15px 48px;
  .panel {
    box-shadow: none;
  }
  .panel-default {
    border: none;
  }
  .item-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
    margin-bottom: 24px;
  }
  a {
    color: black
  }
  a:hover {
    text-decoration: none;
  }
}
