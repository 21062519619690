$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.steps-section {
  padding: 48px 0;
  background: linear-gradient(333.2deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 99.18%), #3A499A;
  color: #FFFFFF;
  .step-item {
    display: flex;
    justify-content: center;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      padding: 0;
    }
    @media (max-width: $screen-sm-max) {
      padding: 0;
      margin-bottom: 32px;
    }
    .first {
      padding: 0 65px 0 0;
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding: 0;
      }
      @media (max-width: $screen-sm-max) {
        padding: 0;
      }
    }
    .second {
      padding-left: 30px;
      @media (max-width: $screen-sm-max) {
        padding: 0;
      }
    }
    .last {
      padding-left: 80px;
      padding-right: 0;
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-left: 24px;
      }
      @media (max-width: $screen-sm-max) {
        padding-left: 0;
        margin-bottom: 0;
      }
    }
    .video-thumbnail {
      border-radius: 8px;
      width: 72px;
      margin-right: 10px;
      margin-top: 5px;
      cursor: pointer
    }
    .step-number {
      background: #FFFFFF;
      width: 26px;
      height: 26px;
      padding: 0px 8px;
      border-radius: 50%;
      font-size: 18px;
      line-height: 25px;
      font-style: normal;
      font-weight: 700;
      color: #082265;
      text-transform: capitalize;
      margin-right: 8px;
    }
    .flex-video-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      width: 280px;
      height: 25px;
      margin-bottom: 4px;
    }
    p {
      width: 280px;
      height: 42px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 2px;
    }
    a {
      line-height: 21px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-decoration-line: underline;
      color: #FFFFFF;
      scroll-behavior: smooth;
    }
  }
}
