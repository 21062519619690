$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.sell-your-car-section {
  padding: 48px 15px;
  background: linear-gradient(333.2deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 99.18%), #3A499A;
  color: #FFFFFF;
  p.sell-your-car-heading {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    padding-right: 46px;
    @media (max-width: $screen-sm-max) {
      padding-right: 5px;
    }
    @media (max-width: $screen-xs-max) {
      padding-bottom: 16px;
    }
  }
  .sell-cta {
    margin: 0 auto;
    .btn {
      margin-bottom: 16px;
      width: 100%;
    }
    img {
      width: 100%;
      margin-left: 12px
    }
    @media (min-width: $screen-sm-min) {
      max-width: 298px;
    }
  }
}
