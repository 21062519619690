$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 1025px;
$screen-md-min: $screen-md;
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
.buyer-landing-popular-vehicles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .popular-vehicles-content {
    width: 1220px;
    padding: 64px 0;
    background: #FFFFFF;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 995px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 736px;
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
      text-align: center;
    }
    .popular-vehicles-text {
      padding-left: 11px;
      text-align: left;
    }
    h3 {
      font-size: 24px;
      line-height: 36px;
      margin: 0;
      font-style: normal;
      font-weight: 700;
    }
    .all-link {
      padding: 8px 0 16px;
    }
  }
}
